import React, { useEffect, useState } from "react";
import MainWrapper from "../../components/layout/MainWrapper";
import CenterViewBanner from "../../components/centerViewBanner/CenterViewBanner";
import Trending from "../../components/trending/Trending";
import BlogCards from "../../components/blogCards/BlogCards";
import { IBlog } from "../../../medEvaluation_module/types/blog.interface";
import HeroBanner from "../../../medEvaluation_module/components/medEvaluationBanner/HeroBanner";

const BlogsScreen = () => {
  const [trendingBlog, setTrendingBlog] = useState<IBlog>();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <div className="invisible opacity-0 h-0 overflow-hidden">
            <h1>Child Care Forward Blog</h1>
              <p>Insights, Stories, and Solutions for Childcare Professionals</p>
              <h2>Latest Blog Posts</h2>
              <h3>Industry Trends and Insights</h3>
              <h3>Best Practices for Childcare Centers</h3>
              <h3>Success Stories from the Field</h3>
              <h2>Explore Key Topics</h2>
              <h3>Digital Transformation in Childcare</h3>
              <h3>Staff Management and Training</h3>
              <h3>Compliance and Licensing Made Simple</h3>
              <h2>Featured Articles</h2>
              <h3>Must-Read Posts for Childcare Owners</h3>
              <h3>Spotlight on Innovative Solutions</h3>
              <h3>Expert Advice and Tips</h3>
              <h2>Resources for Childcare Professionals</h2>
              <h3>Guides and Checklists</h3>
              <h3>Case Studies and Success Stories</h3>
              <h3>Tools for Operational Efficiency</h3>
              <h2>Stay Updated</h2>
              <h3>Subscribe to Our Newsletter</h3>
              <h3>Follow Us on Social Media</h3>
              <h3>Never Miss an Update</h3> 
          </div>
      <MainWrapper>
        <div className="mb-4 md:mb-10 xl:mb-16">
          <HeroBanner
            title="Taking the Child Care
Industry "
            highlightedText="Forward!"
            paragraphText={
              "Transforming childcare operations through innovative services and technology​."
            }
            bgClass="https://a360csastorage.blob.core.windows.net/childcare/a0317cc0-5be3-48ad-8d73-599ac16fc1e5-fileName-blogs-banner.png"
          />{" "}
        </div>
        <div className="container">
          <div className="mb-4 sm:mb-8 md:mb-12 xl:mb-16">
            <Trending trendingBlog={trendingBlog as IBlog} />
          </div>
        </div>
        <div>
          <BlogCards setTrendingBlog={setTrendingBlog} />
        </div>
      </MainWrapper>
    </>
  );
};

export default BlogsScreen;
