import { useEffect } from "react";
import CcfHomeBanner from "../../../shared/components/ccfHomeBanner/CcfHomeBanner";
import MainWrapper from "../../../shared/components/layout/MainWrapper";
import TrustedCustomer from "../../../shared/components/trustedCustomer/TrustedCustomer";
import CanAchive from "../../components/canAchive/CanAchive";
import NewsAndResearch from "../../components/newsAndResearch/NewsAndResearch";

const CcfHomePage = () => {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <>
    <div className="invisible opacity-0 h-0 overflow-hidden">
    <h1>Simplify Medical Evaluations for Childcare Centers</h1> 
      <p>Your Solution for Fast, Reliable, and Online Medical Compliance</p> 
      <h2>Why Choose Our Online Medical Evaluations?</h2> 
      <h3>Fast and Convenient Scheduling</h3> 
      <h3>Fully Online Evaluations</h3> 
      <h3>Compliance Made Easy for Centers</h3> 
      <h2>Key Benefits for Childcare Centers and Staff</h2> 
      <h3>Reduce Staff Downtime</h3> 
      <h3>Meet Licensing Requirements Quickly</h3> 
      <h3>Reliable and Secure Process</h3> 
      <h2>How It Works</h2>  
      <h3>Step 1 Schedule Online</h3>  
      <h3>Step 2 Complete Evaluation</h3> 
      <h3>Step 3 Get Results in 48 Hours</h3> 
      <h2>Frequently Asked Questions (FAQs)</h2> 
      <h3>Is the process fully online?</h3> 
      <h3>How long does it take to get results?</h3> 
      <h3>Are the evaluations accepted for licensing?</h3> 
      <h2>Get Started Today</h2>
      <h3>Book Your Evaluation Now </h3>
      <h3>Contact Our Support Team</h3>
    </div>
      <MainWrapper>
        <div>
          <CcfHomeBanner />
          <div className="container ">
            <div className="grid lg:grid-cols-12 xl:gap-12 lg:gap-4 md:gap-4 gap-4 py-6 md:py-12 lg:py-16">
              <div className="lg:col-span-8 flex flex-col">
                <h2 className="text-2xl md:text-xll xl:text-4xl text-secondary font-semibold mb-3 text-start">
                  What can I Achieve?
                </h2>
                <p className="text-lg text-secondaryVariant mb-4 md:mb-9">
                  Bringing everyone together to create efficient and
                  cost-effective childcare.
                </p>

                <div className="grid lg:grid-cols-3 md:grid-cols-3 gap-3 lg:gap-4 xl:gap-6 flex-1">
                  <CanAchive />
                </div>
              </div>
              <div className="lg:col-span-4">
                <NewsAndResearch />
              </div>
            </div>
          </div>
          {/* <FrequentlyQuestions /> */}
          {/* <BlogCards /> */}
          <TrustedCustomer />
        </div>
      </MainWrapper>
    </>
  );
};

export default CcfHomePage;
